import React from 'react';

function ListItem({ url, openDrawer, openModal }) {
  return (
    <li className="list-item">
      <div className="list-item-link" >
        <a href={url.link} target="_blank" rel="noopener noreferrer">
          <span className="list-title">{url.name}</span>
          <span>{url.link}</span>
        </a>
        <div className="list-actions">
          <img src="../img/editar.svg" alt="editar" onClick={() => openDrawer(url)} />
          <img src="../img/eliminar.svg" alt="eliminar" onClick={() => openModal(url.id)} />
        </div>
      </div>
    </li>
  )
}

export default ListItem;
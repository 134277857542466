import React from 'react';

function Footer() {
  return (
    <footer>
      <div className="footer-bottom">
        <span> &copy; TODO1 Services Inc. | Derechos Reservados {new Date().getFullYear()}.</span>
      </div>
    </footer>
  );
}

export default Footer;
import React from 'react';

function Modal({ show, hideModal, deleteUrl }) {
  return (
    <div id="modal" className={`${show ? 'active' : ''}`} >
      <div className={`modal-card ${show ? "active" : ""}`}>
        <h2 className="modal-title">Eliminar URL</h2>
        <p className="modal-legend">Estas a punto de eliminar una URL</p>
        <div className="modal-buttons">
          <button className="btn btn-secondary" onClick={hideModal}>Cancelar</button>
          <button className="btn btn-primary" onClick={deleteUrl}>Eliminar</button>
        </div>
      </div>
    </div >
  )
}

export default Modal;
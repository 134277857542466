import React, { useState, useEffect } from 'react';

function Drawer({ show, hideDrawer, url, addUrl, updateUrl }) {
  const [name, setName] = useState(url.name);
  const [link, setLink] = useState(url.url);
  const [errors, setErrors] = useState({});

  useEffect(() => { setName(url.name) }, [url]);
  useEffect(() => { setLink(url.link) }, [url]);

  // Actualizo el valor del nombre
  const onChangeName = (e) => setName(e.target.value)

  // Actualizo el valor de la url
  const onChangeUrl = (e) => setLink(e.target.value)

  const handleUrl = (e) => {
    e.preventDefault();

    // Validar campos
    let errors = {}
    if (!name)
      errors.name = "El nombre no puede estar vacio"
    if (!link)
      errors.url = "La URL no puede estar vacia"

    setErrors(errors)

    // Verifico que el array de erores este vacío
    if (Object.keys(errors).length !== 0) {
      return;
    }

    let newUrl = {}
    newUrl.link = link
    newUrl.name = name

    if (url.id === null || url.id === undefined) {
      addUrl(newUrl)
      setName('')
      setLink('')
    } else {
      newUrl.id = url.id
      updateUrl(newUrl)
    }

    hideDrawer()
    setErrors({})
  }

  const _hideDrawer = () => {
    hideDrawer()
    setName(url.name)
    setLink(url.link)
    setErrors({})
  }

  return (
    <div id="drawer" className={`${show ? 'active' : ''}`} >
      <div className={`drawer-card ${show ? "active" : ""}`}>
        <div className="drawer-top">
          <span> {(url.id === null || url.id === undefined) ? "Nueva Demo" : "Editar Demo"}</span>
          <img src="img/times-solid.svg" onClick={_hideDrawer} alt="" />
        </div>

        <div id="monto-transferir" className="drawer-content">

          <form onSubmit={handleUrl}>
            <div className="form-group form-group-custom">
              <label htmlFor="name">Nombre</label>
              <input
                type="text"
                className={`form-control form-control-custom ${errors.name && "is-invalid"}`}
                id="name"
                name="name"
                value={name || ""}
                placeholder="Kyma Demo"
                onChange={onChangeName} />
              <div className="invalid-feedback">{errors.name}</div>
            </div>
            <div className="form-group form-group-custom">
              <label htmlFor="url">URL</label>
              <input
                type="text"
                className={`form-control form-control-custom ${errors.url && "is-invalid"}`}
                id="url"
                name="url"
                value={link || ""}
                placeholder="https://kyma.demo.iuvity.com"
                onChange={onChangeUrl} />
              <div className="invalid-feedback">{errors.url}</div>
            </div>
            <input type="submit" className="sr-only" />
          </form>
          {
            (url.id === null || url.id === undefined) ?
              <button type="submit" className="btn btn-success" onClick={handleUrl}>Cargar</button> :
              <button className="btn btn-success" onClick={handleUrl}>Guardar cambios</button>
          }
        </div>
      </div>
    </div >
  )
}

export default Drawer;
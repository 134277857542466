import React, { useState, useEffect } from 'react';
import './App.scss';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import DemoList from './pages/DemoList';
import Demos from './pages/Demos';

import { API_ROUTE } from './conexion';

function App() {
  let [urls, setUrls] = useState([])

  useEffect(() => {
    fetch(API_ROUTE + "get_urls.php")
      .then(res => res.json())
      .then(res => setUrls(res.reverse()))
    // .catch(() => this.setState({ hasErrors: true }))
  }, []);

  return (
    <Router>
      <Route exact path="/" component={() => <Demos urls={urls} />} />
      <Route exact path="/admin" component={() => <DemoList />} />
    </Router>

  );
}

export default App;

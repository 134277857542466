import React from 'react';

import Footer from '../components/Footer';

function Demos({ urls }) {
  return (
    <div id="demos">
      <section >
        <div className="container header">
          <div className="title-wrapper">
            <img src="../img/logo.svg" alt="" />
            <h1 className="section-title text-center">Demostraciones</h1>
            <span></span>
          </div>
        </div>

        <div className="container">
          <div className="row justify-content-center">
            <div className="col col-lg-8">
              <ul className="list">
                {
                  urls.map(url => (
                    <li className="list-item" key={url.id}>
                      <a className="list-item-link" href={url.link} target="_blank" rel="noopener noreferrer">
                        <span><b>{url.name}</b></span>
                        <img src="../img/right-arrow.svg" alt="" />
                      </a>
                    </li>
                  ))
                }
              </ul>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default Demos;
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

//components
import List from '../components/List';
import Drawer from '../components/Drawer';
import Modal from '../components/Modal';
import Footer from '../components/Footer';

import { API_ROUTE } from '../conexion';

function DemoList(props) {
  let [urls, setUrls] = useState([])
  const [url, setUrl] = useState([])
  const [id, setId] = useState(null)
  const [showDrawer, setShowDrawer] = useState(false)
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    getUrls()
  }, []);

  const getUrls = () => {
    fetch(API_ROUTE + "get_urls.php")
      .then(res => res.json())
      .then(res => setUrls(res.reverse()))
    // .catch(() => this.setState({ hasErrors: true }))
  }

  const openDrawer = (url) => {
    (url !== null) ? setUrl(url) : setUrl(null)
    setShowDrawer(true)
  }

  const hideDrawer = () => {
    setShowDrawer(false);
    // prevent scrolling on desktop
    document.body.style.overflow = 'auto';
  }

  const openModal = (id) => {
    setId(id)
    setShowModal(true)
  }

  const hideModal = () => {
    setShowModal(false);
    setId(null)
  }

  const addUrl = (newUrl) => {
    fetchDB(newUrl, "url_add.php")
  }

  const updateUrl = (newUrl) => {
    fetchDB(newUrl, "url_edit.php")
  }

  const deleteUrl = () => {
    fetchDB({ id }, "url_delete.php")
    hideModal()
  }

  const fetchDB = async (data, url) => {
    await fetch(API_ROUTE + url, {
      method: 'POST',
      body: JSON.stringify(data),
    }).then(res => res.json())
      .then(response => console.log('Success:', JSON.stringify(response)))
    // .catch(error => console.error('Error:', error));
    getUrls()
  }

  return (
    <div id="demos" >

      <section >

        <Drawer show={showDrawer} hideDrawer={hideDrawer} url={url} addUrl={addUrl} updateUrl={updateUrl} />
        <Modal show={showModal} hideModal={hideModal} deleteUrl={deleteUrl} />

        <div className="container header">
          <div className="title-wrapper">
            <img src="../img/logo.svg" alt="" />
            <h1 className="section-title text-center">Alta y Baja de Demostraciones</h1>
            <span>
              <Link className="link" to="/">Salir</Link>
            </span>
          </div>
        </div>

        <div className="container">
          <div className="row justify-content-center">
            <div className="col col-lg-8">
              <ul className="list">
                <li className="list-item">
                  <span className="list-item-link list-item-button" onClick={openDrawer}>
                    <span>Nueva demo</span>
                    <img src="../img/add.svg" alt="" />
                  </span>
                </li>
                <List urls={urls} openDrawer={openDrawer} openModal={openModal} />
              </ul>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div >
  )
}

export default DemoList;